import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../actions/alert';
import { crearVacacion, buscarColaboradores } from '../../actions/colaboradores';

const NuevaVacacion = ({ vacacion, reestablecerVacacion }) => {

    const dispatch =  useDispatch();

    const { listado: listado_colaboradores } = useSelector(state => state.colaboradores);

    const [vacacionData, setVacacionData] = useState({
        id: null, 
        periodos: [
            {
                inicio: new Date(), 
                fin: new Date()
            }
        ], 
        observaciones: '', 
        formato: 1
    });

    const [colaboradorData, setColaboradorData] = useState(null);
    const [colaboradoresFilter, setColaboradoresFilter] = useState([]);

    const [vacacionModal, setVacacionModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Agregar vacacion */
    const handleAbrir = () => {
        dispatch(buscarColaboradores({
            status: [10]
        }));
    }
    
    const handleSelect = (option) => {
        setColaboradorData(option);

        setVacacionData({
            ...vacacionData,
            id: option?.value ?? null
        });
    }

    const handleInputChange = ({ target }) => {
        setVacacionData({
            ...vacacionData,
            [target.name]: target.value
        });
    }

    const handleAgregarPeriodo = () => {
        let periodos = vacacionData.periodos;

        periodos.push({
            inicio: new Date(), 
            fin: new Date()
        });

        setVacacionData({ 
            ...vacacionData, 
            periodos
        });
    }

    const periodosCampos = (periodos) => {
        let periodos_campos = [];

        if(periodos.length > 0){
            for (let x = 0; x < periodos.length; x++) {
                periodos_campos.push(
                    <div className="col-md-6 form-group mb-3" key={'periodo_' + x}>
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <DatePicker
                                    id={'fechas_' + x}
                                    name={'fechas_' + x}
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange={true}
                                    startDate={periodos[x].inicio}
                                    endDate={periodos[x].fin}
                                    autoComplete="off"
                                    onChange={ date => handleDatePicker('fechas_' + x, date) }
                                />
                                <label className="label-datepicker" htmlFor="fin">Rango de fechas</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarPeriodo_' + x} onClick={e => handleEliminarPeriodo(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }else{
            periodos_campos.push(
                <div className="col-md-12 form-group my-3 text-center">
                    <p>Define al menos un periodo de fechas.</p>
                </div>
            );
        }

        return periodos_campos;
    }

    const handleDatePicker = (name, value) => {
        const [inicio, fin] = value;

        let id_separado = name.split('_');

        let periodos = vacacionData.periodos;
        periodos[id_separado[1]].inicio = inicio;
        periodos[id_separado[1]].fin = fin;

        setVacacionData({
            ...vacacionData,
            [name]: value
        });
    }

    const handleEliminarPeriodo = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let periodos = vacacionData.periodos;

        periodos.splice(id_separado[1], 1);

        setVacacionData({ 
            ...vacacionData, 
            periodos
        });
    }

    const handleProcesar = async () => {
        if(colaboradorData){
            if(vacacionData.periodos.length > 0){
                let interferencias = 0;

                for (let x = 0; x < vacacionData.periodos.length; x++) {
                    for (let i = 0; i < vacacionData.periodos.length; i++) {
                        if(x !== i){
                            if((new Date(vacacionData.periodos[x].inicio).getTime() >= new Date(vacacionData.periodos[i].inicio).getTime() && new Date(vacacionData.periodos[x].inicio).getTime() <= new Date(vacacionData.periodos[i].fin).getTime()) || (new Date(vacacionData.periodos[x].fin).getTime() >= new Date(vacacionData.periodos[i].inicio).getTime() && new Date(vacacionData.periodos[x].fin).getTime() <= new Date(vacacionData.periodos[i].fin).getTime())){
                                interferencias++;
                            }
                        }
                    }
                }

                if(interferencias == 0){
                    await setStatusProcesar(true);
                    await dispatch(crearVacacion(vacacionData));
                    await handleCerrar();
                    await setStatusProcesar(false);
                }else{
                    await dispatch(setAlert('Uno o mas periodos interfieren entre si', 'danger'));
                }
            }else{
                await dispatch(setAlert('El o los periodos vacacionales son requeridos', 'danger'));
            }
        }else{
            await dispatch(setAlert('El colaborador asignado es requerido', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setVacacionData({
                id: null, 
                periodos: [
                    {
                        inicio: new Date(), 
                        fin: new Date()
                    }
                ], 
                observaciones: '', 
                formato: 1
            });
            
            setColaboradorData(null);
            reestablecerVacacion(null);
            setVacacionModal(false);
        }
    }

    useEffect(() => {
        if(vacacion.colaborador){
            setVacacionModal(true);

            setVacacionData({
                ...vacacionData, 
                id: vacacion.colaborador
            });
        }
    }, [vacacion]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_colaboradores?.length; i++) {
            options.push({ value: listado_colaboradores[i].id, label: [listado_colaboradores[i].nombre, listado_colaboradores[i].apellido].join(' ') });
        }

        setColaboradoresFilter(options);
    }, [listado_colaboradores]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <Modal show={vacacionModal} size="lg" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva solicitud de vacaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group contenedor-select">
                                <Select
                                    id="id"
                                    name="id"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Seleccionar colaborador"
                                    isClearable
                                    options={colaboradoresFilter}
                                    value={colaboradorData}
                                    onChange={(value) => handleSelect(value)}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                        </div>

                        { periodosCampos(vacacionData.periodos) }

                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Observaciones" id="observaciones" name="observaciones" value={vacacionData.observaciones} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="observaciones">Observaciones</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                    {
                        statusProcesar ?
                        <button type="button" className="btn btn-success mr-3" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                        :
                        <button type="button" className="btn btn-success mr-3" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                    }
                    <button type="button" className="btn btn-primary" onClick={() => handleAgregarPeriodo()} disabled={ statusProcesar }><i className="fas fa-plus fa-sm"></i> Fecha</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default NuevaVacacion;