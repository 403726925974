import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { setAlert } from '../../../../actions/alert';
import { buscarAreasConfiguracion, limpiarOrganizadorConfiguracion, obtenerOrganizadorConfiguracion, editarOrganizadorConfiguracion } from '../../../../actions/configuracion';

import { CustomValueContainer } from '../../../../components/react_select/ReactSelect';

const EditarOrganizador = ({ organizador, reestablecerOrganizador }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.configuracion.organizadores);
    const { listado: listado_areas } = useSelector(state => state.configuracion.areas);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [organizadorData, setOrganizadorData] = useState({
        id: null,
        id_area: null, 
        area: false,
        nombre: null
    });

    const [areaData, setAreaData] = useState(null);
    const [areasFilter, setAreasFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingTop: '10px',
            paddingBottom: '5px',
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Editar organizador */
    const handleInputChange = ({ target }) => {
        setOrganizadorData({
            ...organizadorData,
            [target.name]: target.value
        });
    }

    const handleSelect = (option) => {
        setAreaData(option);

        setOrganizadorData({
            ...organizadorData,
            id_area: option != null ? option.value : null
        });
    }
    
    const handleProcesar = async () => {
        if(organizadorData.nombre && organizadorData.id_area != 0 && organizadorData.id_area != '0'){
            await setStatusProcesar(true);
            await dispatch(editarOrganizadorConfiguracion(organizadorData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setOrganizadorData({
                id: null,
                id_area: null, 
                nombre: null
            });
            setAreaData(null);
            setEditarModal(false);
            reestablecerOrganizador(null);
        }
    }

    useEffect(() => {
        dispatch(buscarAreasConfiguracion());
        dispatch(limpiarOrganizadorConfiguracion());
    }, []);

    useEffect(() => {
        if(organizador){
            setEditarModal(true);
            
            dispatch(obtenerOrganizadorConfiguracion(organizador?.id));

            setOrganizadorData({
                ...organizadorData,
                id: organizador?.id
            });
        }
    }, [organizador]);

    useEffect(() => {
        if(detalles){
            setOrganizadorData({
                ...organizadorData,
                id: detalles?.id, 
                id_area: detalles?.id_area, 
                area: detalles?.id_area == 0 ? false : true,
                nombre: detalles?.nombre
            });
        }
    }, [detalles]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_areas.length; i++) {
            options.push({ value: listado_areas[i].id, label: listado_areas[i].nombre });
        }

        setAreasFilter(options);
    }, [listado_areas]);

    return (
        <Modal show={editarModal} backdrop="static" size="md" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Editar organizador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {
                        detalles?.id_area == 0 || detalles?.id_area == '0' ?
                            <div className="col-md-12 form-group mb-2">
                                <div className="input-group h-100">
                                    <Select
                                        id="id"
                                        name="id"
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder="Área"
                                        options={areasFilter}
                                        value={areaData}
                                        isClearable={true}
                                        onChange={(value) => handleSelect(value)}
                                        menuPortalTarget={document.body}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{ ...styles_select }}
                                    />
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={organizadorData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditarOrganizador;