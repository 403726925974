import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../../actions/breadcrumb';
import { obtenerCategoriaConfiguracion, buscarSubcategoriasConfiguracion } from '../../../../actions/configuracion';

import NuevaSubcategoria from './NuevaSubcategoria';
import EditarSubcategoria from './EditarSubcategoria';
import EliminarSubcategoria from './EliminarSubcategoria';

const Subcategorias = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Categorias',
            path: '/configuracion/categorias'
        },
        {
            activo: true,
            nombre: 'Subcategorias',
            path: '/configuracion/categorias/subcategorias/'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.subcategorias);
    const { detalles } = useSelector(state => state.configuracion.categorias);

    const { id } = useParams();

    const [subcategorias, setSubcategorias] = useState([]);

    const [subcategoriaAgregar, setSubcategoriaAgregar] = useState(null);
    const [subcategoriaEditar, setSubcategoriaEditar] = useState(null);
    const [subcategoriaEliminar, setSubcategoriaEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion,
            grow: 2
        },
        {
            name: 'Productos existentes',
            selector: row => row.productos,
            grow: 0.5
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarSubcategoria(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarSubcategoria(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Crear subcategoria */
    const handleAgregarSubcategoria = (subcategoria) => {
        setSubcategoriaEditar(null);
        setSubcategoriaEliminar(null);

        setSubcategoriaAgregar(subcategoria);        
    }

    /* Editar subcategoria */
    const handleEditarSubcategoria = (subcategoria) => {
        setSubcategoriaAgregar(null);
        setSubcategoriaEliminar(null);

        setSubcategoriaEditar(subcategoria);
    }

    /* Eliminar subcategoria */
    const handleEliminarSubcategoria = (subcategoria) => {
        setSubcategoriaAgregar(null);
        setSubcategoriaEditar(null);

        setSubcategoriaEliminar(subcategoria);
    }

    useEffect(() => {
        setSubcategorias(listado);
    }, [listado]);

    useEffect(() => {
        if(id){
            dispatch(obtenerCategoriaConfiguracion(id));
            dispatch(buscarSubcategoriasConfiguracion(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    return (
        <Fragment>

            <NuevaSubcategoria subcategoria={subcategoriaAgregar} reestablecerSubcategoria={handleAgregarSubcategoria} />
            <EditarSubcategoria subcategoria={subcategoriaEditar} reestablecerSubcategoria={handleEditarSubcategoria} />
            <EliminarSubcategoria subcategoria={subcategoriaEliminar} reestablecerSubcategoria={handleEliminarSubcategoria} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Subcategorias {detalles?.nombre ? ' de ' + detalles.nombre : ''}</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAgregarSubcategoria({ id_categoria: id })}>Agregar subcategoria</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={subcategorias}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Subcategorias;