import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput from 'react-currency-input-field';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { crearColaborador, buscarColaboradores } from '../../../actions/colaboradores';
import { buscarTiposColaboradores } from '../../../actions/tipos_colaboradores';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevoColaborador = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Colaboradores',
            path: '/colaboradores/colaboradores'
        },
        {
            activo: true,
            nombre: 'Nuevo colaborador',
            path: '/colaboradores/colaboradores/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { listado: listado_tipos } = useSelector(state => state.tipos_colaboradores);
    const { listado: listado_colaboradores } = useSelector(state => state.colaboradores);

    const [colaboradorData, setColaboradorData] = useState({
        nombre: '',
        apellido: '',
        nacimiento: '', 
        email: '',
        telefono: '',
        telefono_casa: '',
        direccion: '',
        medio_transporte: '',
        tiempo_traslado: '',
        originario: '',
        residencia: '',
        estado_civil: '',
        curp: '', 
        rfc: '', 
        banco: '', 
        numero_cuenta: '', 
        clabe_interbancaria: '', 
        username: '',
        password: '',
        passwordConf: '', 
        pasatiempos: '', 

        id_tipo_usuario: '', 
        tipo_contrato: '', 
        puesto: '',
        id_jefe_directo: '', 
        email_laboral: '',
        telefono_laboral: '',
        sueldo_mensual: '', 
        tipo_sueldo: '', 
        dias_vacaciones: '', 
        licenciatura: '',
        cedula_licenciatura: '',
        especialidad: '',
        cedula_especialidad: '',
        estudios_extra: '',
        idiomas_extra: '',
        titulos: '', 
        firma: false, 

        tipo_sangre: '',
        seguro: '', 
        compania_seguro: '', 
        poliza_seguro: '', 
        contactos: [], 
        hijos: [], 
        anotaciones: []
    });

    const [estadoCivilData, setEstadoCivilData] = useState(null);
    const estadosCivilesFilter = [
        { value: 1, label: 'Soltero' }, 
        { value: 2, label: 'Casado' }, 
        { value: 3, label: 'Divorciado' }, 
        { value: 4, label: 'Viudo' }
    ];
    
    const [tipoUsuarioData, setTipoUsuarioData] = useState(null);
    const [tiposUsuarioFilter, setTiposUsuariosFilter] = useState([]);

    const [tipoContratoData, setTipoContratoData] = useState(null);
    const tiposContratoFilter = [
        { value: 1, label: 'Medio tiempo' }, 
        { value: 2, label: 'Tiempo completo' }, 
        { value: 3, label: 'Freelance' }, 
        { value: 4, label: 'Remoto' }
    ];

    const [jefeDirectoData, setJefeDirectoData] = useState(null);
    const [jefesDirectosFilter, setJefesDirectosFilter] = useState([]);

    const [tipoSueldoData, setTipoSueldoData] = useState(null);
    const tiposSueldoFilter = [
        { value: 1, label: 'Neto' }, 
        { value: 2, label: 'Bruto' }
    ];

    const [tipoSangreData, setTipoSangreData] = useState(null);
    const tiposSangresFilter = [
        { value: 1, label: 'A+' },
        { value: 2, label: 'A-' },
        { value: 3, label: 'B+' },
        { value: 4, label: 'B-' },
        { value: 5, label: 'AB+' },
        { value: 6, label: 'AB-' },
        { value: 7, label: 'O+' },
        { value: 8, label: 'O-' }
    ];

    const [seguroData, setSeguroData] = useState(null);
    const segurosFilter = [
        { value: 1, label: 'Privado' },
        { value: 2, label: 'Público' }, 
        { value: 3, label: 'No' }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nuevo colaborador */
    const handleInputChange = ({ target }) => {
        setColaboradorData({
            ...colaboradorData,
            [target.name]: target.value
        });
    }

    const handleNumberChange = (name, value) => {
        setColaboradorData({
            ...colaboradorData,
            [name]: value || ''
        });
    }

    const handleDatePicker = (name, value) => {
        setColaboradorData({
            ...colaboradorData,
            [name]: value
        });
    }

    const handleSelectEstadoCivil = (option) => {
        setEstadoCivilData(option);

        setColaboradorData({
            ...colaboradorData,
            estado_civil: option != null ? option.value : null
        });
    }

    const handleCheckbox = ({ target }) => {
        setColaboradorData({
            ...colaboradorData,
            firma: target.checked
        });
    }

    const handleSelectTipoUsuario = (option) => {
        setTipoUsuarioData(option);

        setColaboradorData({
            ...colaboradorData,
            id_tipo_usuario: option != null ? option.value : null
        });
    }

    const handleSelectTipoContrato = (option) => {
        setTipoContratoData(option);

        setColaboradorData({
            ...colaboradorData,
            tipo_contrato: option != null ? option.value : null
        });
    }

    const handleSelectJefeDirecto = (option) => {
        setJefeDirectoData(option);

        setColaboradorData({
            ...colaboradorData,
            id_jefe_directo: option != null ? option.value : null
        });
    }

    const handleSelectTipoSueldo = (option) => {
        setTipoSueldoData(option);

        setColaboradorData({
            ...colaboradorData,
            tipo_sueldo: option != null ? option.value : null
        });
    }

    const handleSelectTipoSangre = (option) => {
        setTipoSangreData(option);

        setColaboradorData({
            ...colaboradorData,
            tipo_sangre: option != null ? option.value : null
        });
    }

    const handleSelectSeguro = (option) => {
        setSeguroData(option);

        setColaboradorData({
            ...colaboradorData,
            seguro: option != null ? option.value : null
        });
    }

    const handleGuardarColaborador = async () => {
        if(colaboradorData.username && colaboradorData.password && colaboradorData.passwordConf && colaboradorData.nombre && colaboradorData.apellido && colaboradorData.id_tipo_usuario != null && colaboradorData.id_tipo_usuario != undefined){
            if(colaboradorData.password == colaboradorData.passwordConf){
                await dispatch(crearColaborador(colaboradorData));
                await navigate("/colaboradores/colaboradores");
            }else{
                await dispatch(setAlert('Las contraseñas no coinciden', 'danger'));
            }
        }else{
            await dispatch(setAlert('El usuario, contraseña, nombre, apellido y el tipo de cuenta son obligatorios', 'danger'));
        }
    }

    /* Contactos de emergencia */
    const handleAgregarContacto = () => {
        let contactos = colaboradorData.contactos;

        contactos.push({
            nombre: null,
            parentesco: null,
            telefono: null
        });

        setColaboradorData({ 
            ...colaboradorData, 
            contactos
        });
    }

    const handleContactoChange = ({ target }) => {
        let nombre_separado = target.name.split('Contacto_');

        let posicion = nombre_separado[1];
        let name = nombre_separado[0];
        
        let contactos = colaboradorData.contactos;

        contactos[posicion][name] = target.value || '';

        setColaboradorData({
            ...colaboradorData,
            contactos
        });
    }

    const handleFilasContactosCampos = (contactos) => {

        let contactos_campos = [];

        if(contactos){
            for (let x = 0; x < contactos?.length; x++) {
                contactos_campos.push(
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id={'nombreContacto_' + x} name={'nombreContacto_' + x} value={contactos[x].nombre || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'nombreContacto_' + x}>Contacto {x + 1}</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Parentesco" id={'parentescoContacto_' + x} name={'parentescoContacto_' + x} value={contactos[x].parentesco || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'parentescoContacto_' + x}>Parentesco {x + 1}</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Teléfono" id={'telefonoContacto_' + x} name={'telefonoContacto_' + x} value={contactos[x].telefono || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'telefonoContacto_' + x}>Teléfono {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarContacto_' + x} onClick={e => handleEliminarContacto(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return contactos_campos;
    }

    const handleEliminarContacto = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let contactos = colaboradorData.contactos;

        contactos.splice(id_separado[1], 1);

        setColaboradorData({ 
            ...colaboradorData, 
            contactos
        });
    }

    /* Hijos */
    const handleAgregarHijo = () => {
        let hijos = colaboradorData.hijos;

        hijos.push({
            nombre: null
        });

        setColaboradorData({ 
            ...colaboradorData, 
            hijos
        });
    }

    const handleHijoChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let hijos = colaboradorData.hijos;

        hijos[posicion].nombre = target.value || '';

        setColaboradorData({
            ...colaboradorData,
            hijos
        });
    }

    const handleFilasHijosCampos = (hijos) => {

        let hijos_campos = [];

        if(hijos){
            for (let x = 0; x < hijos?.length; x++) {
                hijos_campos.push(
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id={'nombreHijo_' + x} name={'nombreHijo_' + x} value={hijos[x].nombre || ''} onChange={e => handleHijoChange(e)}/>
                                <label htmlFor={'nombreHijo_' + x}>Hijo {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarHijo_' + x} onClick={e => handleEliminarHijo(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return hijos_campos;
    }

    const handleEliminarHijo = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let hijos = colaboradorData.hijos;

        hijos.splice(id_separado[1], 1);

        setColaboradorData({ 
            ...colaboradorData, 
            hijos
        });
    }

    /* Anotaciones */
    const handleAgregarAnotacion = () => {
        let anotaciones = colaboradorData.anotaciones;

        anotaciones.push({
            contenido: null
        });

        setColaboradorData({ 
            ...colaboradorData, 
            anotaciones
        });
    }

    const handleAnotacionChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let anotaciones = colaboradorData.anotaciones;

        anotaciones[posicion].contenido = target.value || '';

        setColaboradorData({
            ...colaboradorData,
            anotaciones
        });
    }

    const handleFilasAnotacionesCampos = (anotaciones) => {

        let anotaciones_campos = [];

        if(anotaciones){
            for (let x = 0; x < anotaciones?.length; x++) {
                anotaciones_campos.push(
                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Contenido" id={'contenidoAnotacion_' + x} name={'contenidoAnotacion_' + x} value={anotaciones[x].contenido || ''} onChange={e => handleAnotacionChange(e)}/>
                                <label htmlFor={'contenidoAnotacion_' + x}>Anotación {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarAnotacion_' + x} onClick={e => handleEliminarAnotacion(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return anotaciones_campos;
    }

    const handleEliminarAnotacion = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let anotaciones = colaboradorData.anotaciones;

        anotaciones.splice(id_separado[1], 1);

        setColaboradorData({ 
            ...colaboradorData, 
            anotaciones
        });
    }

    /* Funciones generales */
    const onViewPassword = (origen) => {
        if(colaboradorData.password !== ''){
            let loginInputType = document.getElementById(origen).type;
            
            if(loginInputType === 'password')document.getElementById(origen).type = 'text';
            else document.getElementById(origen).type = 'password';
        }
    }

    useEffect(() => {
        dispatch(buscarTiposColaboradores());
        dispatch(buscarColaboradores({
            status: [10]
        }));
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_tipos.length; i++) {
            options.push({ value: listado_tipos[i].id, label: listado_tipos[i].nombre });
        }

        setTiposUsuariosFilter(options);
    }, [listado_tipos]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_colaboradores.length; i++) {
            options.push({ value: listado_colaboradores[i].id, label: [listado_colaboradores[i].nombre, listado_colaboradores[i].apellido].join(' ') });
        }

        setJefesDirectosFilter(options);
    }, [listado_colaboradores]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #85ac11;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #caec67;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Nuevo colaborador</h3>
                    
                    <div className="row mt-3">
                        <div className="accordion" id="accordionDetalles">
                                
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Información personal
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={colaboradorData.nombre} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="nombre">Nombre</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Apellido" id="apellido" name="apellido" value={colaboradorData.apellido} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="apellido">Apellido</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Correo" id="email" name="email" value={colaboradorData.email} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="email">Correo</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <DatePicker
                                                        id="nacimiento"
                                                        name="nacimiento"
                                                        className="form-control text-center input-datepícker"
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={ colaboradorData.nacimiento ? new Date(colaboradorData.nacimiento) : '' }
                                                        onChange={ date => handleDatePicker('nacimiento', date) }
                                                    />
                                                    <label className="label-datepicker" htmlFor="nacimiento">Nacimiento</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono" id="telefono" name="telefono" value={colaboradorData.telefono} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono">Teléfono personal</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono" id="telefono_casa" name="telefono_casa" value={colaboradorData.telefono_casa} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono_casa">Teléfono de casa</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Dirección" id="direccion" name="direccion" value={colaboradorData.direccion} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="direccion">Dirección</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Medio de transporte" id="medio_transporte" name="medio_transporte" value={colaboradorData.medio_transporte} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="medio_transporte">Medio de transporte</label>
                                                </div>
                                            </div>
                                        </div>  

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Tiempo de traslado" id="tiempo_traslado" name="tiempo_traslado" value={colaboradorData.tiempo_traslado} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="tiempo_traslado">Tiempo de traslado</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Originario" id="originario" name="originario" value={colaboradorData.originario} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="originario">Originario</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <DatePicker
                                                        id="residencia"
                                                        name="residencia"
                                                        className="form-control text-center input-datepícker"
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={ colaboradorData.residencia ? new Date(colaboradorData.residencia) : '' }
                                                        onChange={ date => handleDatePicker('residencia', date) }
                                                    />
                                                    <label className="label-datepicker" htmlFor="residencia">Llegada a Ags</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="estado_civil"
                                                    name="estado_civil"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Estado civil"
                                                    options={estadosCivilesFilter}
                                                    value={estadoCivilData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectEstadoCivil(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="CURP" id="curp" name="curp" value={colaboradorData.curp} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="curp">CURP</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="RFC" id="rfc" name="rfc" value={colaboradorData.rfc} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="rfc">RFC</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Banco" id="banco" name="banco" value={colaboradorData.banco} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="banco">Banco</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="No. de cuenta" id="numero_cuenta" name="numero_cuenta" value={colaboradorData.numero_cuenta} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="numero_cuenta">No. de cuenta</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="CLABE" id="clabe_interbancaria" name="clabe_interbancaria" value={colaboradorData.clabe_interbancaria} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="clabe_interbancaria">CLABE</label>
                                                </div>
                                            </div>
                                        </div>                                     

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Usuario" id="username" name="username" value={colaboradorData.username} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="email">Usuario de acceso</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="password" className="form-control" placeholder="Contraseña" id="password" name="password" value={colaboradorData.password} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="password">Contraseña</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password')}><i className="fa-solid fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="password" className="form-control" placeholder="Confirmar Contraseña" id="passwordConf" name="passwordConf" value={colaboradorData.passwordConf} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="passwordConf">Confirmar contraseña</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('passwordConf')}><i className="fa-solid fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Hobbies" id="pasatiempos" name="pasatiempos" value={colaboradorData.pasatiempos} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="pasatiempos">Hobbies</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    Información profesional
                                </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="id_tipo_usuario"
                                                    name="id_tipo_usuario"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Tipo"
                                                    options={tiposUsuarioFilter}
                                                    value={tipoUsuarioData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoUsuario(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_contrato"
                                                    name="tipo_contrato"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Contrato"
                                                    options={tiposContratoFilter}
                                                    value={tipoContratoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoContrato(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Puesto" id="puesto" name="puesto" value={colaboradorData.puesto} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="puesto">Puesto</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="id_jefe_directo"
                                                    name="id_jefe_directo"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Jefe directo"
                                                    options={jefesDirectosFilter}
                                                    value={jefeDirectoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectJefeDirecto(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Correo empresarial" id="email_laboral" name="email_laboral" value={colaboradorData.email_laboral} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="email_laboral">Correo empresarial</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono empresarial" id="telefono_laboral" name="telefono_laboral" value={colaboradorData.telefono_laboral} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono_laboral">Teléfono empresarial</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <CurrencyInput
                                                        name="sueldo_mensual"
                                                        className="form-control text-center"
                                                        value={colaboradorData.sueldo_mensual || ''}
                                                        decimalsLimit={2}
                                                        groupSeparator=","
                                                        decimalSeparator="."
                                                        onValueChange={(value, name) => handleNumberChange(name, value)}
                                                    />
                                                    <label htmlFor="email">Sueldo mensual</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_sueldo"
                                                    name="tipo_sueldo"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Tipo de sueldo"
                                                    options={tiposSueldoFilter}
                                                    value={tipoSueldoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoSueldo(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Vacaciones" id="dias_vacaciones" name="dias_vacaciones" value={colaboradorData.dias_vacaciones} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="dias_vacaciones">Vacaciones</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Licenciatura" id="licenciatura" name="licenciatura" value={colaboradorData.licenciatura} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="licenciatura">Licenciatura</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Cédula de licenciatura" id="cedula_licenciatura" name="cedula_licenciatura" value={colaboradorData.cedula_licenciatura} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="cedula_licenciatura">Cédula de licenciatura</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Especialidad" id="especialidad" name="especialidad" value={colaboradorData.especialidad} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="especialidad">Especialidad</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Cédula de especialidad" id="cedula_especialidad" name="cedula_especialidad" value={colaboradorData.cedula_especialidad} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="cedula_especialidad">Cédula de especialidad</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Estudios extra" id="estudios_extra" name="estudios_extra" value={colaboradorData.estudios_extra} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="estudios_extra">Estudios extra</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Idiomas extra" id="idiomas_extra" name="idiomas_extra" value={colaboradorData.idiomas_extra} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="idiomas_extra">Idiomas extra</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Títulos" id="titulos" name="titulos" value={colaboradorData.titulos} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="titulos">Títulos</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 form-group mb-3">
                                            <div className="input-group h-100">
                                                <div className="input-group-preppend w-100">
                                                    <input type="checkbox" className="btn-check" id="firma" autoComplete="off" onChange={e => handleCheckbox(e)} checked={colaboradorData.firma ? true : false}/>
                                                    <label className="btn btn-outline-primary h-100 w-100" id="label_firma" htmlFor="firma">{colaboradorData.firma ? 'Agregar espacio para firma en documentos' : 'No agregar espacio para firma en documentos'}</label>
                                                </div>                                   
                                            </div>                              
                                        </div> 
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    Información familiar
                                </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-12 text-end mb-3">
                                            <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarContacto()}><i className="fa-solid fa-plus"></i>  Contacto</button>
                                            <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarHijo()}><i className="fa-solid fa-plus"></i>  Hijo</button>
                                            <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarAnotacion()}><i className="fa-solid fa-plus"></i>  Anotación</button>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_sangre"
                                                    name="tipo_sangre"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Tipo de sangre"
                                                    options={tiposSangresFilter}
                                                    value={tipoSangreData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoSangre(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="seguro"
                                                    name="seguro"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="¿Seguro médico?"
                                                    options={segurosFilter}
                                                    value={seguroData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectSeguro(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Compañia" id="compania_seguro" name="compania_seguro" value={colaboradorData.compania_seguro} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="compania_seguro">Compañia o institución</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Póliza" id="poliza_seguro" name="poliza_seguro" value={colaboradorData.poliza_seguro} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="poliza_seguro">Póliza o número</label>
                                                </div>
                                            </div>
                                        </div>

                                        { handleFilasContactosCampos(colaboradorData.contactos) }
                                        { handleFilasHijosCampos(colaboradorData.hijos) }
                                        { handleFilasAnotacionesCampos(colaboradorData.anotaciones) }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarColaborador}>Guardar</button>
                            <Link to='/colaboradores'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoColaborador;