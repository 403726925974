import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { setAlert } from '../../../../actions/alert';
import { buscarCategoriasConfiguracion, crearSubcategoriaConfiguracion } from '../../../../actions/configuracion';

import { CustomValueContainer } from '../../../../components/react_select/ReactSelect';

const NuevaSubcategoria = ({ subcategoria, reestablecerSubcategoria }) => {

    const dispatch =  useDispatch();

    const { listado: listado_categorias } = useSelector(state => state.configuracion.categorias);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [subcategoriaData, setSubcategoriaData] = useState({
        id: null,
        categoria: false,
        nombre: null, 
        descripcion: null
    });

    const [categoriaData, setCategoriaData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingTop: '10px',
            paddingBottom: '5px',
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Crear subcategoria */
    const handleInputChange = ({ target }) => {
        setSubcategoriaData({
            ...subcategoriaData,
            [target.name]: target.value
        });
    }

    const handleSelect = (option) => {
        setCategoriaData(option);

        setSubcategoriaData({
            ...subcategoriaData,
            id: option != null ? option.value : null
        });
    }
    
    const handleProcesar = async () => {
        if(subcategoriaData.nombre && subcategoriaData.id != 0 && subcategoriaData.id != '0'){
            await setStatusProcesar(true);
            await dispatch(crearSubcategoriaConfiguracion(subcategoriaData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setSubcategoriaData({
                id: null,
                nombre: null, 
                descripcion: null
            });
            setCategoriaData(null);
            setNuevoModal(false);
            reestablecerSubcategoria(null);
        }
    }

    useEffect(() => {
        dispatch(buscarCategoriasConfiguracion());
    }, []);

    useEffect(() => {
        if(subcategoria){
            setNuevoModal(true);

            setSubcategoriaData({
                ...subcategoriaData, 
                id: subcategoria.id_categoria, 
                categoria: subcategoria.id_categoria == 0 ? false : true
            });
        }        
    }, [subcategoria]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_categorias.length; i++) {
            options.push({ value: listado_categorias[i].id, label: listado_categorias[i].nombre });
        }

        setCategoriasFilter(options);
    }, [listado_categorias]);

    return (
        <Modal show={nuevoModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar subcategoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {
                        subcategoria?.id_categoria == 0 || subcategoria?.id_categoria == '0' ?
                            <div className="col-md-12 form-group mb-2">
                                <div className="input-group h-100">
                                    <Select
                                        id="id"
                                        name="id"
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder="Categoria"
                                        options={categoriasFilter}
                                        value={categoriaData}
                                        isClearable={true}
                                        onChange={(value) => handleSelect(value)}
                                        menuPortalTarget={document.body}
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{ ...styles_select }}
                                    />
                                </div>
                            </div>
                        :
                            null
                    }

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={subcategoriaData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={subcategoriaData.descripcion} onChange={e => handleInputChange(e)} rows="3"></textarea>
                                <label htmlFor="descripcion">Descripción</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaSubcategoria;